// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/img/logo.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".full-layout {\n  /* */\n  display: flex;\n  flex: 1 1;\n  height: 100vh;\n  justify-content: center;\n  align-items: center;\n}\n\n.logo {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-position: center;\n  display: block;\n  height: 58px;\n}", "",{"version":3,"sources":["webpack://src/layouts/fullLayout/FullLayout.css"],"names":[],"mappings":"AAAA;EACE,IAAI;EACJ,aAAa;EACb,SAAO;EACP,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,yDAAkD;EAClD,wBAAwB;EACxB,4BAA4B;EAC5B,2BAA2B;EAC3B,cAAc;EACd,YAAY;AACd","sourcesContent":[".full-layout {\n  /* */\n  display: flex;\n  flex: 1;\n  height: 100vh;\n  justify-content: center;\n  align-items: center;\n}\n\n.logo {\n  background-image: url('../../assets/img/logo.svg');\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-position: center;\n  display: block;\n  height: 58px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
