import React, { useEffect, useState } from 'react';
import { Row, Col, Notification, Input, Button } from 'im-ui-pack';
import { request, ContentTypes } from '../../store/api/api';

const ModalViewPanel = (props: any) => {
  const { close, accountItem } = props;
  const [accountSession, setAccountSession] = useState(null);

  useEffect(()=>{
    console.log('ModalCancelSession: accountItem: ', accountItem);
  })

  const closeModal = () => {
    close();
  }

  useEffect(()=> {
    if(accountSession===null) {
      getAccountSession()
    }
  })

  async function getAccountSession() {
    const data = {
      account_id: accountItem.account_id
    };
    const endpoint = `/account/session`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      console.log('>>>>>> result: ', result)
      if (result&& result.account_id) {
        setAccountSession(result);
      }
    } catch (err) {
      console.log(err)
    }
  };
 /* const handleInputChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }
*/
if (!accountSession) {
  return (
    <div>
      <div className='modal-header'>Ver panel</div>
      <div className='modal-content'>
      </div>
    </div>
  )
}
  return (
    <div>
      <div className='modal-header'>Ver panel</div>
      <div className='modal-content'>
        <Row>
          <Col>
            Email:
            <Input name='' value={accountSession.account_email} />
          </Col>
        </Row>
        <Row>
          <Col>
            Contraseña:
            <Input name='' value={accountSession.account_password} />
          </Col>
        </Row>
      </div>  
      <div className='modal-bottom'>
        <Button className='secondary-outline' action={()=>closeModal()} label='Cancelar'/>
      </div>
    </div>
  )
}

export default ModalViewPanel;
