import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Section, Row, Col, H2, Tabs, Tab, Table, Thead, Tbody, Tr, Th, Td, Button, NavigationBar, NavigationLink, Modal, JS } from 'im-ui-pack';
import { request, ContentTypes } from '../../../../store/api/api';
// import items from '../../../../store/items/ItemsReducers';
// import deal_data from './../../../../store/mock/deal.json';

function DealOverview() {
  const [componentModal, setComponentModal] = useState<any|undefined>(undefined);
  const [meets, setMeets]=useState();

  useEffect(() => {
    if(!meets) {
      getMeets();
    }
  });

  // Get branch related to the meet
  async function getMeets() {
    const data = {
      
    };
    const endpoint = `/professional/meets`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      console.log('*************** Meets filter result: ', result);
      setMeets(result);
    } catch (err) {
      console.log(err)
    }
  };

  function saveModal() {
    console.log('Save');
    setComponentModal(null);
  }

  function closeModal() {
    setComponentModal(null);
  }

  /*
  const getMeetTime = (meet:any) => {
    return meet.map((meetItem)=>{
      return (
        <div>
          <div>{meetItem.time_begin}</div>
          <div>{meetItem.time_end}</div>
        </div>
      );
    });
  }

  const getMeetAmount = (meet:any) => {
    let total_amount = 0;
    meet.forEach(meetItem => {
      total_amount += parseFloat(meetItem.amount);
    })
    return total_amount;
  }

  const getMeetProfessionalSubscribers = (meet: any) => {
    let total_professional_subscribers = 0;
    meet.forEach(meetItem => {
      total_professional_subscribers += parseFloat(meetItem.professional_subscribers);
    })
    return total_professional_subscribers;
  }

  const getMeetCover = (meet: any) => {
    if(meet.find(meetItem=> meetItem.cover==='no')) {
      return (<Link to={''}>Asignar profesional</Link>);
    }
    return (<Link to={''}>Profesional asignado</Link>);
  }*/

  return ( 
    <div>
      <Section>
        <Row>
          <Col>
            <H2>Ofertas</H2>
            <NavigationBar>
              <NavigationLink href="https://admin.improovia.com/">Admin</NavigationLink>
            </NavigationBar>
          </Col>
          <Col className='align-right'>
          </Col>
        </Row>
        <Row>
          <Col>

            <Table>
              <Thead>
              <Tr>
                <Th>Sucursal</Th><Th>Fecha de cita</Th><Th>Presupuesto</Th><Th></Th>
              </Tr>
              </Thead>
              <Tbody>
                {
                  meets ? (
                    meets.map((item: any)=> {
                      const meet_sessions = JSON.parse(item.meet_sessions);
                      return (
                        <Tr key={item.meet_id}>
                          <Td>{item.branch_name}</Td>
                          <Td>{JS.meetFormat.interval(item.meet_time_start,item.meet_time_end)}</Td>
                          <Td>{meet_sessions.length}</Td>
                          <Td>
                            <div className='position-right btn-group'>
                              <Link to={`/dashboard/meet/assign-professional/${item.meet_id}`}>
                                <Button label='Asignar profesional' action={()=>null}/>
                              </Link>
                            </div>
                          </Td>
                        </Tr>
                      )
                    })
                  ) : null

                }
              {/*
                branch_assessment_data.map((item: any)=> {
                  return (
                    <Tr key={item.assessment_id}>
                      <Td>{item.name}</Td>
                      <Td>{getMeetTime(item.meet)}</Td>
                      <Td>€{getMeetAmount(item.meet)}</Td>
                      <Td>
                        <div className='position-right btn-group'>
                          {}
                          <Button className='primary' label='Aplicar' action={()=>showModalCreateDeal()}/>
                        </div>
                      </Td>
                    </Tr>
                  )
                })
              */}
              </Tbody>
            </Table>

          </Col>
        </Row>
      </Section>
      <Modal close={()=>closeModal()} component={componentModal}/>
    </div>
  );


/*
  return ( 
    <div>
      <Section>
        <Row>
          <Col><H2>Ofertas</H2>
            <NavigationBar>
              <NavigationLink href="https://admin.improovia.com/">Admin</NavigationLink>
            </NavigationBar>
          </Col>
          <Col className='align-right'>
          </Col>
        </Row>
        <Row>
          <Col>

            <Table>
              <Thead>
              <Tr>
                <Th>Empresa</Th><Th>Fecha</Th><Th>Presupuesto</Th><Th>Profesionales inscriptos</Th><Th>Cubierto</Th><Th></Th>
              </Tr>
              </Thead>
              <Tbody>
              {
                deal_data.map((item: any)=> {
                  return (
                    <Tr key={item.branch_id}>
                      <Td>{item.name}</Td><Td>{getMeetTime(item.meet)}</Td><Td>{getMeetAmount(item.meet)}</Td><Td>{getMeetProfessionalSubscribers(item.meet)}</Td><Td>{getMeetCover(item.meet)}</Td><Td><div className='position-right'><a href='https://professional.improovia.com/dashboard'><Button className='primary' label='Detalle' /></a></div></Td>
                    </Tr>
                  )
                })
              }
              </Tbody>
            </Table>

          </Col>
        </Row>
      </Section>
    </div>
  );*/
}

export default DealOverview;