import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Section, Row, Col, H2, Tabs, Tab, Table, Thead, Tbody, Tr, Th, Td, Button } from 'im-ui-pack';
import professional_new_data from './../../../../../../store/mock/professional_new.json';

function CompanyIncidence() {

  useEffect(() => {});

  return (
    <div>
      <Table>
        <Thead>
        <Tr>
          <Th>Nombre de profesional</Th><Th>Locación</Th><Th></Th>
        </Tr>
        </Thead>
        <Tbody>
        {
          professional_new_data.map((item: any)=> {
            return (
              <Tr key={item.id}>
                <Td>{item.firstname} {item.lastname}</Td><Td>{item.location.city}</Td><Td><div className='position-right'><Button className='alert' label='Resolver' /></div></Td>
              </Tr>
            )
          })
        }
        </Tbody>
      </Table>
    </div>
  )
}

export default CompanyIncidence;
