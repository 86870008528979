import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Section, Row, Col, H2, Tabs, Tab, Table, Thead, Tbody, Tr, Th, Td } from 'im-ui-pack';
import { requestSwitcherAPI, ContentTypes } from '../../../../store/api/api';
// import items from '../../../../store/items/ItemsReducers';
import AccountingIncome from './tab/income/Income';
import InvoiceCompany from './tab/invoiceCompany/InvoiceCompany';
import CompanyIncidence from './tab/incidence/Incidence';
import EmployeePayment from './tab/employeePayment/EmployeePayment';
import EmployeeDebt from './tab/employeeDebt/EmployeeDebt';

function AccountingOverview() {

  useEffect(() => {});

  return ( 
    <div>
      <Section>
        <Row>
          <Col><H2>Contabilidad</H2></Col>
          <Col className='align-right'>
          </Col>
        </Row>
        <Row>
          <Col>
          
            <Tabs>
              <Tab data='Ingresos'>
                <div><AccountingIncome /></div>
              </Tab>
              <Tab data='Facturas a empresas'>
                <div><InvoiceCompany /></div>
              </Tab>
              <Tab data='Pagos de empleados'>
                <div><EmployeePayment /></div>
              </Tab>
              <Tab data='Empleados morosos'>
                <div><EmployeeDebt /></div>
              </Tab>
            </Tabs>
          
          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default AccountingOverview;