import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Section, Row, Col, H2, Tabs, Tab} from 'im-ui-pack';
import { requestSwitcherAPI, ContentTypes } from '../../../../store/api/api';
// import items from '../../../../store/items/ItemsReducers';
import ProfessionalActive from './tab/active/Active';
import ProfessionalNew from './tab/new/New';
import ProfessionalIncidence from './tab/incidence/Incidence';

function ProfessionalOverview() {

  useEffect(() => {});

  return ( 
    <div>
      <Section>
        <Row>
          <Col>
            <H2>Profesionales</H2>
          </Col>
          <Col className='align-right'>
          </Col>
        </Row>
        <Row>
          <Col>
            <Tabs>
              <Tab data='Activos'>
                <div><ProfessionalActive /></div>
              </Tab>
              <Tab data='Nuevas solicitudes'>
                <div><ProfessionalNew /></div>
              </Tab>
              {/*
              <Tab data='Incidencias'>
                <div><ProfessionalIncidence /></div>
              </Tab>
              */}
            </Tabs>
          
          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default ProfessionalOverview;