import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Section, Row, Col, H2, NavigationBar, Notification, NavigationLink, JS } from 'im-ui-pack';
import { request, ContentTypes } from '../../../../store/api/api';

// import items from '../../../../store/items/ItemsReducers';
import { useSelector } from 'react-redux';

function ProfileProfessional() {
  const { professional_id } = useParams();
  const [professional, setProfessional] = useState(null);
  const [notification, setNotification] = useState({status: false, style: '', message: ''});
  // const { professional } = useSelector((state: RootState) => state.default.professional);
  const [formData, setFormData] = useState({
    professional_status: ''
  });

  useEffect(()=> {
    if(professional===null) {
      getProfessional()
    }
    console.log('formData:', formData);
  })

  async function getProfessional() {
    const data = {
      professional_id
    };
    const endpoint = `/professional`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      console.log('result: ', result)
      if (result&& result.account_id) {
        const professional_data = result;
        formData.professional_status=result.professional_status;
        setFormData(formData);
        setProfessional(result);
      }
    } catch (err) {
      console.log(err)
    }
  };

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  async function handleUpdateProfessional (event) {
    event.preventDefault();
    console.log('formData: ', formData);
    const data = {
      "professional_id": professional_id,
      "professional_status": formData.professional_status
    }

    const endpoint = `/professional/update`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const { result } = res.data;
      console.log(res.data);
      setNotification({
        status: true,
        className: 'success',
        message: 'Los cambios se han guardado correctamente.'
      });
    } catch (err) {
      console.log(err)
      setNotification({
        status: true,
        className: 'danger',
        message: 'Error al ingresar los datos.'
      });
    }
  }

  return ( 
    <div>
      <Section>
        <Row>
          <Col>
            <H2>Cambiar estado</H2>
            <NavigationBar>
              <NavigationLink href="https://admin.improovia.com/">Admin</NavigationLink>
            </NavigationBar>
          </Col>
          <Col className='align-right'>
          </Col>
        </Row>
      {professional ? (
        <div>
          {(notification.status) ? (
            <Row>
              <Col>
                <Notification className={notification.className}>{notification.message}</Notification>
              </Col>
            </Row>) : null
          }
        <form onSubmit={ e =>handleUpdateProfessional(e)}>
          <Row>
            <Col className='w-20'>Nombre completo</Col><Col>{professional.professional_fullname}</Col>
          </Row>
          <Row>
            <Col className='w-20'>Email</Col><Col>{professional.account_email}</Col>
          </Row>
          <Row>
            <Col className='w-20'>Tel</Col><Col>{professional.professional_tel}</Col>
          </Row>
          <Row>
            <Col className='w-20'>Fecha de nacimiento</Col><Col>{professional.professional_birthday}</Col>
          </Row>
          <Row>
            <Col className='w-20'>Domicilio</Col><Col>{professional.professional_location_address}</Col>
          </Row>
          <Row>
            <Col className='w-20'>Ciudad</Col><Col>{professional.professional_location_city}</Col>
          </Row>
          <Row>
            <Col className='w-20'>Provincia</Col><Col>{professional.professional_location_state}</Col>
          </Row>
          <Row>
            <Col className='w-20'>Número de colegiado</Col><Col>{professional.professional_registration}</Col>
          </Row>
          <Row>
            <Col className='w-20'>Especialidad</Col><Col>{professional.professional_type}</Col>
          </Row>
          {/*<Row>
            <Col className='w-20'>Seguro de responsabilidad civil</Col><Col><Link to={'/file.pdf'}>Descargar Seguro.pdf</Link></Col>
      </Row>*/}
          <Row>
            <Col className='w-20'>Años de experiencia laboral</Col><Col>{professional.professional_experience} años</Col>
          </Row>
          <Row>
            <Col className='w-20'>Camilla portatil</Col><Col>{professional.professional_portable ? 'Sí' : 'No'}</Col>
          </Row>
          <Row>
            <Col className='w-20'>Alta autónomos</Col><Col>{professional.professional_freelance ? 'Sí' : 'No'}</Col>
          </Row>
          <Row>
            <Col className='w-20'>Estado</Col>
            <Col className='w-20'>
              <select className='inp' name="professional_status" onChange={(e)=>handleInputChange(e)}>
                <option value="inactive">Inactivo</option>
                <option value="active">Activo</option>
              </select>
            </Col>
          </Row>
          <Row>
            <Col>
              <button className='btn primary'>Guardar</button>
            </Col>
          </Row>
        </form>
          {/*
          <Row>
            <Col className='w-20'>Documentos</Col><Col><Link to={'/file.pdf'}>Descargar Contrato Improovia.pdf</Link></Col>
          </Row>
          */}

        </div>
      ) : null
      }
      </Section>
    </div>
  );
}

export default ProfileProfessional;