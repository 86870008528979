import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td, Button, H2, NavigationBar, NavigationLink, Notification, Row, Col, Section, Modal } from 'im-ui-pack';
import { request, ContentTypes } from '../../../../store/api/api';
import ModalAssignProfessional from './modalAssignProfessional/ModalAssignProfessional';

function AssignProfessional() {
  const { meet_id } = useParams();
  const [professionals, setProfessionals] = useState();
  const [componentModal, setComponentModal] = useState<any|undefined>(undefined);
  const [professionalSelected, setProfessionalSelected] = useState();
  const [notification, setNotification] = useState({status: false, style: '', message: ''});

  useEffect(() => {
    if(!professionals) {
      getProfessionals();
    }
  });

  async function getProfessionals() {
    const data = {
      "professional_status" : "active"
    };
    const endpoint = `/professional/list`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      //console.log('result: ', result);
      setProfessionals(result);
    } catch (err) {
      console.log(err)
    }
  };

  function showModalAssignProfessional(professionalItem) {
    setComponentModal(<ModalAssignProfessional close={()=>closeModal()} save={(modalData)=>saveModal(modalData)} professional={professionalItem} />);
  }

  function saveModal(modalData) {
    if (modalData.assign_accept) {
      assignProfessionalSave(modalData);
    }
    setComponentModal(null);
  }

  function closeModal() {
    setComponentModal(null);
  }

  async function assignProfessionalSave(modalData) {
    // event.preventDefault();

    const { professional_id } = modalData;

    const data = {
      "meet_id": meet_id,
      "professional_id": professional_id
    }

    const endpoint = `/meet/update`;
    // console.log('data: ', data);

    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const { result } = res.data;
      if(result) {
        setNotification({
          status: true,
          className: 'success',
          message: 'El profesional se ha asignado correctamente.'
        });
      }
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <div>
      <Section>
        <Row>
          <Col>
            <H2>Asignar profesional</H2>
            <NavigationBar>
              <NavigationLink href="https://admin.improovia.com/">Admin</NavigationLink>
            </NavigationBar>
          </Col>
          <Col className='align-right'>
          </Col>
        </Row>
        {(notification.status) ? (
          <Row>
            <Col>
              <Notification className={notification.className}>{notification.message}</Notification>
            </Col>
          </Row>) : null
        }
        <Row>
          <Col>
      <Table>
        <Thead>
        <Tr>
          <Th>Nombre de profesional</Th><Th>Locación</Th><Th></Th>
        </Tr>
        </Thead>
        <Tbody>
        {
          professionals ? (
            professionals.map((item: any)=> {
              return (
                <Tr key={item.professional_id}>
                  <Td>{item.professional_fullname}</Td><Td>{item.professional_location_city}</Td><Td><div className='position-right'><Button className='primary' label='Asignar este profesional' action={()=> showModalAssignProfessional(item)}/></div></Td>
                </Tr>
              )
            })
          ): null
        }
        </Tbody>
      </Table>
      </Col>
    </Row>
  </Section>
  <Modal close={()=>closeModal()} component={componentModal} professional={professionalSelected}/>
    </div>
  )
}

export default AssignProfessional;
