import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Section, Row, Col, H2, H5, NavigationBar, NavigationLink, Notification, JS } from 'im-ui-pack';
import { request, ContentTypes } from '../../../../store/api/api';

// import items from '../../../../store/items/ItemsReducers';
import { useSelector } from 'react-redux';

function ProfileCompany() {
  const { company_id } = useParams();
  const [company, setCompany] = useState(null);
  // const { professional } = useSelector((state: RootState) => state.default.professional);
  const [formData, setFormData] = useState({
    company_status: ''
  });
  const [notification, setNotification] = useState({status: false, style: '', message: ''});

  useEffect(()=> {
    if(company===null) {
      getCompany()
    }
    console.log('formData:', formData);
    console.log('company: ', company)
  })

  async function getCompany() {
    const data = {
      company_id
    };
    const endpoint = `/company`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      console.log('result: ', result)
      if (result&& result.company_id) {
        // const professional_data = result;
        formData.company_status=result.company_status;
        setFormData(formData);
        setCompany(result);
      }
    } catch (err) {
      console.log(err)
    }
  };

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  async function handleUpdateCompany (event) {
    event.preventDefault();
    console.log('formData: ', formData);
    const data = {
      "company_id": company_id,
      "company_status": formData.company_status
    }
    const endpoint = `/company/update`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const { result } = res.data;
      console.log(res.data);
      setNotification({
        status: true,
        className: 'success',
        message: 'Los datos se han actualizado correctamente.'
      });
    } catch (err) {
      console.log(err)
      setNotification({
        status: true,
        className: 'danger',
        message: 'Error al ingresar los datos.'
      });
    }
  }

  return ( 
    <div>
      <Section>
        <Row>
          <Col>
            <H2>Cambiar estado</H2>
            <NavigationBar>
              <NavigationLink href="https://admin.improovia.com/">Admin</NavigationLink>
            </NavigationBar>
          </Col>
          <Col className='align-right'>
          </Col>
        </Row>
      {company ? (
        <div>
          {(notification.status) ? (
            <Row>
              <Col>
                <Notification className={notification.className}>{notification.message}</Notification>
              </Col>
            </Row>) : null
          }
          <form onSubmit={ e =>handleUpdateCompany(e)}>
            <Row>
              <Col><H5>Responsable</H5></Col>
            </Row>
            <Row>
              <Col className='w-20'>Nombre completo</Col><Col>{company.company_manager_fullname}</Col>
            </Row>
            <Row>
              <Col className='w-20'>Email</Col><Col>-</Col>
            </Row>
            <Row>
              <Col className='w-20'>Tel</Col><Col>{company.company_manager_tel}</Col>
            </Row>
            <Row>
              <Col className='w-20'>Fecha de nacimiento</Col><Col>{company.company_manager_birthday}</Col>
            </Row>
            <Row>
              <Col><H5>Empresa</H5></Col>
            </Row>
            <Row>
              <Col className='w-20'>Nombre de empresa</Col><Col>{company.company_name}</Col>
            </Row>
            <Row>
              <Col className='w-20'>Dirección fiscal</Col><Col>{company.company_location_address}</Col>
            </Row>
            <Row>
              <Col className='w-20'>NIF</Col><Col>{company.company_tax_id}</Col>
            </Row>
            <Row>
              <Col className='w-20'>Ciudad</Col><Col>{company.company_location_city}</Col>
            </Row>
            <Row>
              <Col className='w-20'>Provincia</Col><Col>{company.company_location_state}</Col>
            </Row>
            <Row>
              <Col><H5>Acuerdos y contratos</H5></Col>
            </Row>
            <Row>
              <Col className='w-20'>Email responsable de pagos</Col><Col>{company.company_contract_email}</Col>
            </Row>
            <Row>
              <Col className='w-20'>Periodo de liquidación</Col><Col>A {company.company_payment_time} días</Col>
            </Row>
            <Row>
              <Col className='w-20'>Modalidad de pago</Col><Col>{company.company_payment_type}</Col>
            </Row>
            {/*
            <Row>
              <Col className='w-20'>Documentos</Col><Col><Link to={'/file.pdf'}>Descargar Contrato Improovia.pdf</Link></Col>
            </Row>
            */}
            <Row>
              <Col className='w-20'>Estado</Col>
              <Col className='w-20'>
                <select className='inp' name="company_status" onChange={(e)=>handleInputChange(e)}>
                  <option value="inactive">Inactivo</option>
                  <option value="active">Activo</option>
                </select>
              </Col>
            </Row>
            <Row>
              <Col>
                <button className='btn primary'>Guardar</button>
              </Col>
            </Row>
          </form>
          {/*
          <Row>
            <Col className='w-20'>Documentos</Col><Col><Link to={'/file.pdf'}>Descargar Contrato Improovia.pdf</Link></Col>
          </Row>
          */}

        </div>
      ) : null
      }
      </Section>
    </div>
  );
}

export default ProfileCompany;