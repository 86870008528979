import React, { useEffect, useState } from 'react';
import { Row, Col, Notification, Input, Button } from 'im-ui-pack';

const ModalAssignProfessional = (props: any) => {
  const { close, save, professional } = props;
  const { professional_id, professional_fullname } = professional;
  const [formData, setFormData] = useState({
    assign_accept: true,
    professional_id: professional_id
  });

  useEffect(()=>{
    console.log('ModalAssignProfessional: professional: ', professional);
  })

  const saveModal = () => {
    save(formData);
  }

  const closeModal = () => {
    close();
  }

  const handleInputChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  return (
    <div>
      <div className='modal-header'>Assignar profesional</div>
      <div className='modal-content'>
        <Row>
          <Col>
            {`¿Desea asignar a "${professional_fullname}"?`}
          </Col>
        </Row>
      </div>  
      <div className='modal-bottom'>
        <Button className='secondary-outline' action={()=>closeModal()} label='Cancelar'/>
        <Button className='primary' action={()=>saveModal()} label='Asignar'/>
      </div>
    </div>
  )
}

export default ModalAssignProfessional;
