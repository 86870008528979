import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Section, Row, Col, H2, Tabs, Tab } from 'im-ui-pack';
import { requestSwitcherAPI, ContentTypes } from '../../../../store/api/api';
// import items from '../../../../store/items/ItemsReducers';
import CompanyActive from './tab/active/Active';
import CompanyNew from './tab/new/New';
import CompanyIncidence from './tab/incidence/Incidence';

function CompanyOverview() {

  useEffect(() => {});

  return ( 
    <div>
      <Section>
        <Row>
          <Col>
            <H2>Empresas</H2>
          </Col>
          <Col className='align-right'>
          </Col>
        </Row>
        <Row>
          <Col>
          
            <Tabs>
              <Tab data='Activos'>
                <div><CompanyActive /></div>
              </Tab>
              <Tab data='Nuevas solicitudes'>
                <div><CompanyNew /></div>
              </Tab>
              {/*<Tab data='Incidencias'>
                <div><CompanyIncidence /></div>
              </Tab>*/}
            </Tabs>
          
          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default CompanyOverview;