import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td, Button } from 'im-ui-pack';
import accounting_data from './../../../../../../store/mock/accounting.json';

function InvoiceCompany() {

  useEffect(() => {});

  const getInvoiceCompany = () => {
    return (<a href='https://professional.improovia.com/dashboard'>Agregar factura</a>)
  }

  const getPaymentCompany = (payment_company) => {
    if (payment_company==='pending') {
      return 'Pendiente';
    }
    return 'Pagado';
  }

  const getPaymentProfessional = (payment_company) => {
    if (payment_company==='pending') {
      return 'Pendiente';
    }
    return 'Pagado';
  }

  return (
    <div>
      <Table>
        <Thead>
          <Tr>
            <Th>Empresa</Th>
            <Th>Fecha aprox. de pago</Th>
            <Th>Número de factura</Th>
            {/*<Th>Pago de empresa</Th>
            <Th>Pago a profesionales</Th>
          */}
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
        {
          accounting_data.map((item: any)=> {
            return (
              <Tr key={item.branch_id}>
                <Td>{item.name}</Td>
                <Td>{item.payment_estimation}</Td>
                <Td>{getInvoiceCompany(item.invoice_company)}</Td>
                {/*<Td>{getPaymentCompany(item.payment_company)}</Td>
                <Td><Link to={`/dashboard/accounting/payment/professional/${item.branch_id}`}>{getPaymentProfessional(item.payment_professional)}</Link></Td>
                */}
                <Td><div className='position-right'><a href='https://professional.improovia.com/dashboard'><Button className='primary' label='Detalle' /></a></div></Td>
              </Tr>
            )
          })
        }
        </Tbody>
      </Table>
    </div>
  )
}

export default InvoiceCompany;
