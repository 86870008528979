import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Section, Row, Col, H2, Tabs, Tab, Table, Thead, Tbody, Tr, Th, Td, Button, Modal, Notification, LineDivisor } from 'im-ui-pack';
import { requestSwitcherAPI, ContentTypes } from '../../../store/api/api';
// import items from '../../../../store/items/ItemsReducers';

import ModalNewProfile from './modalNewProfile/ModalNewProfile';

function NewProfile() {
  const [componentModal, setComponentModal] = useState<any|undefined>(undefined);

  useEffect(() => {});

  function validateNewProfile() {
    setComponentModal(<ModalNewProfile close={()=>closeModal()} save={()=>saveModal()} />);
  }

  function saveModal() {
    console.log('Save');
    setComponentModal(null);
  }

  function closeModal() {
    setComponentModal(null);
  }

/*
  const getMeetTime = (meet:any) => {
    return meet.map((meetItem)=>{
      return (
        <div>
          <div>{meetItem.time_begin}</div>
          <div>{meetItem.time_end}</div>
        </div>
      );
    });
  }

  const getMeetEmployeeSubscribers = (meet: any) => {
    let total_employee_subscribers = 0;
    meet.forEach(meetItem => {
      total_employee_subscribers += parseFloat(meetItem.employee_subscribers);
    })
    return total_employee_subscribers;
  }

  const getAssessmentSubscription = (item: any) => {
    return (
      <div>
        <div>{item.employee_subscription}/{item.employee_subscription_total}</div>
        <div>{(item.employee_subscription * 100)/item.employee_subscription_total }% Completado</div>
      </div>
    );
  }

  const getTimeBeginEnd = (item: any) => {
    return (
      <div>
        <div>{item.time_begin}</div>
        <div>{item.time_end}</div>
      </div>
    );
  }*/

  return ( 
    <div>
      <Section>
        <Row>
          <Col><H2>Profesional - Nueva solicitud</H2></Col>
          <Col className='align-right'>
          </Col>
        </Row>
      </Section>
      <Section>
        <Row>
          <Col className='w-20'>Nombre</Col><Col>José</Col>
        </Row>
        <Row>
          <Col className='w-20'>Apellidos</Col><Col>Martinez</Col>
        </Row>
        <Row>
          <Col className='w-20'>Email</Col><Col>josemartinez@example.com</Col>
        </Row>
        <Row>
          <Col className='w-20'>Tel</Col><Col>34 673889991</Col>
        </Row>
        <Row>
          <Col className='w-20'>Fecha de nacimiento</Col><Col>20/08/2022</Col>
        </Row>
        <Row>
          <Col className='w-20'>Domicilio</Col><Col>Providencia 69 2do 3ra</Col>
        </Row>
        <Row>
          <Col className='w-20'>Ciudad</Col><Col>Barcelona</Col>
        </Row>
        <Row>
          <Col className='w-20'>Provincia</Col><Col>Barcelona</Col>
        </Row>
      </Section>
      <Section>
        <Row>
          <Col className='w-20'>Número de colegiado</Col><Col>3747332423</Col>
        </Row>
        <Row>
          <Col className='w-20'>Especialidad</Col><Col>Fisioterapeuta</Col>
        </Row>
        <Row>
          <Col className='w-20'>Seguro de responsabilidad civil</Col><Col><Link to={'/file.pdf'}>Descargar Seguro.pdf</Link></Col>
        </Row>
        <Row>
          <Col className='w-20'>Años de experiencia laboral</Col><Col>10 años</Col>
        </Row>
        <Row>
          <Col className='w-20'>Camilla portatil</Col><Col>Sí</Col>
        </Row>
        <Row>
          <Col className='w-20'>Alta autónomos</Col><Col>Sí</Col>
        </Row>
        <Row>
          <Col className='w-20'>Documentos</Col><Col>
            <div className='form-group'>
              <Link to={'/file.pdf'}>Descargar Contrato Improovia.pdf</Link>
              <Link to={'/file.pdf'}>Descargar Contrato Improovia.pdf</Link>
            </div>
          </Col>
        </Row>
      </Section>
      <Section>
        <div className='row'>
          <div className='col align-right btn-group'>
            <Button className='' label='Rechazar' action={()=>null} />
            <Button className='primary' action={()=> validateNewProfile()} label='Validar' />
          </div>
        </div>
      </Section>
      <Modal close={()=>closeModal()} component={componentModal}/>
    </div>
  );
}

export default NewProfile;