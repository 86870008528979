import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Section, Row, Col, H2, H5, Table, Thead, Tbody, Tr, Th, Td, Button, Modal } from 'im-ui-pack';
import { requestSwitcherAPI, ContentTypes } from '../../../../store/api/api';
// import items from '../../../../store/items/ItemsReducers';
import professional_payment_data from './../../../../../../../store/mock/professional_payment.json';
import ModalSolveProfessionalIncidence from './modalSolveProfessionalIncidence/ModalSolveProfessionalIncidence';

function SolveProfessionalIncidence() {
  const [componentModal, setComponentModal] = useState<any|undefined>(undefined);

  useEffect(() => {});

  function showModalSolveProfessionalIncidence() {
    setComponentModal(<ModalSolveProfessionalIncidence close={()=>closeModal()} save={()=>saveModal()} />);
  }

  function saveModal() {
    console.log('Save');
    setComponentModal(null);
  }

  function closeModal() {
    setComponentModal(null);
  }

  return ( 
    <div>
      <Section>
        <Row>
          <Col><H2>Resolver incidencia</H2></Col>
        </Row>
        <Row>
          <Col>
            <H5>Datos de cita</H5>
            <div>Sucursal: Mercadona Gracia</div>
            <div>Locación: Carrer d'Astúries, 68, Gracia - Barcelona</div>
            <div>Fecha: 15/09/2022 - 14hs a 19hs</div>
            <div>Profesional: Miguel Gil</div>
          </Col>
        </Row>
        <Row>
          <Col>
            Motivo por el cual se sustituye al profesional
            <textarea placeholder='Mensaje'></textarea>
          </Col>
        </Row>
        <Row>
          <Col><H5>Sustituir professional</H5></Col>
        </Row>
        <Row>
          <Col>
          
            <Table>
              <Thead>
                <Tr>
                  <Th>Nombre de profesional</Th>
                  <Th>Locación</Th>
                  <Th>Contacto</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
              {
                professional_payment_data.map((item: any)=> {
                  return (
                    <Tr key={item.id}>
                      <Td>{item.firstname} {item.lastname}</Td>
                      <Td>Barcelona</Td>
                      <Td>+34 688 999999</Td>
                      <Td>
                        <div className='position-right'>
                          <div className='btn-group'>
                            <Button className='primary' label='Sustituir' action={()=>showModalSolveProfessionalIncidence()} />
                          </div>
                        </div>
                      </Td>
                    </Tr>
                  )
                })
              }
              </Tbody>
            </Table>

          </Col>
        </Row>
      </Section>
      <Modal close={()=>closeModal()} component={componentModal}/>
    </div>
  );
}

export default SolveProfessionalIncidence;