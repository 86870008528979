import React from 'react';
import { Row, Col, Notification, Input, Button } from 'im-ui-pack';

const ModalNotifyCompanyPayment = (props: any) => {
  const { close, save } = props;

  const saveModal = () => {
    save()
  }

  const closeModal = () => {
    close();
  }

  return (
    <div>
      <div className='modal-header'>Notificar pago de empresa</div>
      <div className='modal-content'>
        <Row>
          <Col>
            <Notification className='alert'>Verifique que el monto y el número de cuenta bancaria sea correcto. Copie y pegue los valores en su sistema de pagos.</Notification>
          </Col>
        </Row>
        <Row>
          <Col>
            <Input type='email' name='payment' value={''} placeholder='Nombre de empresa' onChange={()=>console.log('')}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <Input type='email' name='abono' value={''} placeholder='Monto recibido' onChange={()=>console.log('')}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <Input type='email' name='abono' value={''} placeholder='Numero de factura (Ver “Mensaje de referencia”)' onChange={()=>console.log('')}/>
          </Col>
        </Row>
      </div>  
      <div className='modal-bottom'>
        <Button className='secondary-outline' action={()=>closeModal()} label='Cancelar'/>
        <Button className='primary' action={()=>saveModal()} label='Cambiar a "Pagado"'/>
      </div>
    </div>
  )
}

export default ModalNotifyCompanyPayment;


/*
import React, { useEffect }from 'react';
import './Modals.css';
import Icon from '../icons/Icons';
import Color from '../colors/Colors';

export const Modal = (props: any) => {
  const { handleClose, component } = props;
  // const [startAnimation, setStartAnimation] = useState('initial-animation');
  const startAnimation = '';

  useEffect(() => {
    console.log('start');
    /*if(startAnimation === 'initial-animation') {
      setStartAnimation('start-animation');
    }
  });

  function handleCloseModal() {
    console.log('end');
    handleClose();
  }

  if (component) {
    return (
      <div className={`modal-wrapper ${startAnimation}`}>
        <div className={`modal-box ${startAnimation}`}>
          <div className='modal-header'>
            <button
              className='modal-btn-close'
              onClick={() => handleCloseModal()}
            >
              <Icon icon='IconClose' color={Color.text} size='small' />
            </button>
          </div>
          <div className='modal-container'>
            {props.component}
          </div>
        </div>
      </div>
    );
  }
  return null;
};*/