import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Section, Row, Col, H2, Tabs, Tab, Table, Thead, Tbody, Tr, Th, Td, Button } from 'im-ui-pack';
import professional_new_data from './../../../../../../store/mock/professional_new.json';

function EmployeePayment() {

  useEffect(() => {});

  return (
    <div>
      <Table>
        <Thead>
        <Tr>
          <Th>Nombre de empleado</Th>
          <Th>Fecha de cita</Th>
          <Th>Sucursal</Th>
          <Th>Locación</Th>
          <Th>A pagar</Th>
          <Th>Estado de pago</Th>
          <Th></Th>
        </Tr>
        </Thead>
        <Tbody>
        {
          professional_new_data.map((item: any)=> {
            return (
              <Tr key={item.id}>
                <Td>{item.firstname} {item.lastname}</Td>
                <Td>15/09/2022</Td>
                <Td>Mercadona Gracia</Td>
                <Td>{item.location.city}</Td>
                <Td>€9</Td>
                <Td>Pagado</Td>
                <Td>
                  <div className='position-right'><Button className='primary' label='Detalle' action={()=>null} /></div>
                </Td>
              </Tr>
            )
          })
        }
        </Tbody>
      </Table>
    </div>
  )
}

export default EmployeePayment;
