import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Section, Row, Col, Loading, JS } from 'im-ui-pack';
import './Validate.css';
import { PREFIX_URL } from '../../../store/constants';

function Validate() {
  let url = window.location.href;
  const session_token = url.substring(url.lastIndexOf('/')+1);

  useEffect(()=>{
    test(session_token);
  })

  return (
    <div className='center'>
      <Section maxWidth='small'>
        <Row>
          <Col>
            session_token: {session_token}
          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default Validate;