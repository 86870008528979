import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Section, Row, Col, Button, Input} from 'im-ui-pack';
import messages_data from './../../../../../../store/mock/messages.json';

function CompanyChat() {
  const [customerId, setCustomerId] = useState('Mercadona Gracia');
  const [customersList, setCustomersList] = useState([
    {
      to: 'Mercadona Gracia',
      last_connection: new Date().toLocaleString(), // date format
      read: 0
    },
    {
      to: 'Decathlon Born',
      last_connection: new Date().toLocaleString(), // date format
      read: 0
    }
  ]);
  const [config, setConfig] = useState({
    from: 'support'
  });

  useEffect(() => {});

  return (
    <div className='chat-wrapper'>
      <div className='side-left'>
        <div className='customer-search-wrapper'><Input name='customer_search' value='' placeholder='Buscar' /></div>
        <div className='user-chat-list'>
          {customersList.map((item, index)=>{
            return (
              <div key={index}>
                <div className={`customer-item ${item.to===customerId ? 'selected': ''}`} onClick={()=>console.log()}>
                  <div className='avatar'><div className='icon-avatar'></div></div>
                  <div className='content'>{item.to} {(item.read) ? <div className='customer-new-message'></div> : null}</div>
                </div>
              </div>);
            })
          }
        </div>
      </div>
      <div className='content'>
        <div className='messages'>
          {messages_data.map((item, index) => {
            if(item.to===customerId){
              return (
                <div key={index} className={(item.author===config.from) ? 'message-item me' : 'message-item'}>
                  <div className='avatar'>
                    <div className='icon-avatar'></div>
                  </div>
                  <div className='bubble'>
                    <div className='author'>{item.author}</div>
                    <div className='message'>{item.message}</div>
                    {item.component ? item.component : null}
                  </div>
                </div>);
            }
          })}
        </div>
        <div className='bottom'>
          <div className='form-group'>
            <div>
              <textarea></textarea>
            </div>
            <div className='align-right'>
              <div className='btn-group'>
                <Button label='Adjuntar fichero' />
                <Button className='primary' label='Enviar' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyChat;
