import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td, Button, Modal } from 'im-ui-pack';
import accounting_data from './../../../../../../store/mock/accounting.json';
import ModalNotifyCompanyPayment from './modalNotifyCompanyPayment/ModalNotifyCompanyPayment';

function AccountingIncome() {
  const [componentModal, setComponentModal] = useState<any|undefined>(undefined);

  useEffect(() => {});

  const getInvoiceCompany = () => {
    return (<a href='https://professional.improovia.com/dashboard'>Agregar factura</a>)
  }

  const getPaymentCompany = (payment_company: any) => {
    if (payment_company==='pending') {
      return (
        <Button className='' label='Pendiente' action={()=> showModalNotifyCompanyPayment()}/>
      );
    }
    return 'Pagado';
  }

  const getPaymentProfessional = (payment_company: any) => {
    if (payment_company==='pending') {
      return 'Pendiente';
    }
    return 'Pagado';
  }

  function showModalNotifyCompanyPayment() {
    setComponentModal(<ModalNotifyCompanyPayment close={()=>closeModal()} save={()=>saveModal()} />);
  }

  function saveModal() {
    console.log('Save');
    setComponentModal(null);
  }

  function closeModal() {
    setComponentModal(null);
  }

  return (
    <div>
      <Table>
        <Thead>
          <Tr>
            <Th>Empresa</Th>
            <Th>Fecha de pago</Th>
            <Th>Número de factura</Th>
            <Th>Importe total</Th>
            <Th>Pago de empresa</Th>
            <Th>Pago a profesionales</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
        {
          accounting_data.map((item: any)=> {
            return (
              <Tr key={item.branch_id}>
                <Td>{item.name}</Td>
                <Td>{item.payment_real}</Td>
                <Td>{item.invoice_company}</Td>
                <Td>€{item.payment_amount}</Td>
                <Td>
                  {getPaymentCompany(item.payment_company)}
                </Td>
                <Td>
                  <Link to={`/dashboard/accounting/payment/professional/${item.branch_id}`}>{getPaymentProfessional(item.payment_professional)}</Link>
                </Td>
                <Td>
                  <div className='position-right'>
                    <a href='https://professional.improovia.com/dashboard'><Button className='primary' label='Detalle' /></a>
                  </div>
                </Td>
              </Tr>
            )
          })
        }
        </Tbody>
      </Table>
      <Modal close={()=>closeModal()} component={componentModal}/>
    </div>
  )
}

export default AccountingIncome;
