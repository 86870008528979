import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td, Button, Modal, JS } from 'im-ui-pack';
import { request, ContentTypes } from '../../../../../../store/api/api';
import ModalViewPanel from '../../../../../../components/modalViewPanel/ModalViewPanel';

function ProfessionalActive() {
  const [professionals, setProfessionals] = useState();
  const [componentModal, setComponentModal] = useState<any|undefined>(undefined);

  useEffect(() => {
    if(!professionals) {
      getProfessionals();
    }
  });

  async function getProfessionals() {
    const data = {
      "professional_status" : "active"
    };
    const endpoint = `/professional/list`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      //console.log('result: ', result);
      setProfessionals(result);
    } catch (err) {
      console.log(err)
    }
  };

  function showModalViewPanel(accountItem:any) {
    setComponentModal(<ModalViewPanel close={()=>closeModal()} accountItem={accountItem} />);
  }

  function closeModal() {
    setComponentModal(null);
  }

  return (
    <div>
      <Table>
        <Thead>
        <Tr>
          <Th>Nombre de profesional</Th><Th>Locación</Th><Th>Fecha de creación</Th><Th></Th>
        </Tr>
        </Thead>
        <Tbody>
        {
          professionals ? (
            professionals.map((item: any)=> {
              return (
                <Tr key={item.professional_id}>
                  <Td>{item.professional_fullname}</Td><Td>{item.professional_location_city}</Td><Td>{JS.dateFormat.timestampToDate(item.created_at)}</Td>
                  <Td>
                    <div className='position-right'>
                      {/*<Link to={`/dashboard/change-session/${item.account_id}`}><Button className='primary' label='Ver panel' action={()=>null}/></Link>*/}
                      <Button label='Ver panel' action={()=> showModalViewPanel(item)}/>
                    </div>
                  </Td>
                </Tr>
              )
            })
          ): null
        }
        </Tbody>
      </Table>
      <Modal close={()=>closeModal()} component={componentModal}/>
    </div>
  )
}

export default ProfessionalActive;
