import React from 'react';
import { Button, Row, Col, Notification, Input } from 'im-ui-pack';

const ModalNewProfile = (props: any) => {
  const { close, save } = props;

  const saveModal = () => {
    save()
  }

  const closeModal = () => {
    close();
  }

  return (
    <div>
      <div className='modal-header'>Definir modalidad de pago</div>
      <div className='modal-content'>
        <Row>
          <Col>
            <Notification className='alert'>Al definir la modalidad de pago la empresa se moverá a la lista de "Activos".</Notification>
          </Col>
        </Row>
        <Row>
          <Col>
            <select className='inp' name="select">
              <option value="option1">Pago total empresa</option>
              <option value="option2">Pago total empleado: Pago por cita</option>
              <option value="option3">Pago total empleado: Oferta bono 5 visitas</option>
              <option value="option3">Copago</option>
            </select>
          </Col>
        </Row>
        <Row>
          <Col>
            <Input type='email' name='payment' value={''} placeholder='Pago por cita' onChange={()=>console.log('')}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <Input type='email' name='abono' value={''} placeholder='Oferta bono 5 visitas' onChange={()=>console.log('')}/>
          </Col>
        </Row>
      </div>  
      <div className='modal-bottom'>
        <Button className='secondary-outline' action={()=>closeModal()} label='Cancelar'/>
        <Button className='primary' action={()=>saveModal()} label='Aplicar'/>
      </div>
    </div>
  )
}

export default ModalNewProfile


/*
import React, { useEffect }from 'react';
import './Modals.css';
import Icon from '../icons/Icons';
import Color from '../colors/Colors';

export const Modal = (props: any) => {
  const { handleClose, component } = props;
  // const [startAnimation, setStartAnimation] = useState('initial-animation');
  const startAnimation = '';

  useEffect(() => {
    console.log('start');
    /*if(startAnimation === 'initial-animation') {
      setStartAnimation('start-animation');
    }
  });

  function handleCloseModal() {
    console.log('end');
    handleClose();
  }

  if (component) {
    return (
      <div className={`modal-wrapper ${startAnimation}`}>
        <div className={`modal-box ${startAnimation}`}>
          <div className='modal-header'>
            <button
              className='modal-btn-close'
              onClick={() => handleCloseModal()}
            >
              <Icon icon='IconClose' color={Color.text} size='small' />
            </button>
          </div>
          <div className='modal-container'>
            {props.component}
          </div>
        </div>
      </div>
    );
  }
  return null;
};*/