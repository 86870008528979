import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td, JS, Button } from 'im-ui-pack';
import { request, ContentTypes } from '../../../../../../store/api/api';

function ProfessionalNew() {
  const [professionals, setProfessionals] = useState();

  useEffect(() => {
    if(!professionals) {
      getProfessionals();
    }
  });

  async function getProfessionals() {
    const data = {
      "professional_status" : "inactive"
    };
    const endpoint = `/professional/list`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      //console.log('result: ', result);
      setProfessionals(result);
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <div>
      <Table>
        <Thead>
        <Tr>
          <Th>Nombre de profesional</Th><Th>Locación</Th><Th>Fecha de creación</Th><Th></Th>
        </Tr>
        </Thead>
        <Tbody>
        {
          professionals ? (
            professionals.map((item: any)=> {
              return (
                <Tr key={item.professional_id}>
                  <Td>{item.professional_fullname}</Td><Td>{item.professional_location_city}</Td><Td>{JS.dateFormat.timestampToDate(item.created_at)}</Td><Td><div className='position-right'><Link to={`dashboard/professional/profile/${item.professional_id}`}><Button label='Cambiar estado' action={()=>null}/></Link></div></Td>
                </Tr>
              )
            })
          ): null
        }
        </Tbody>
      </Table>
    </div>
  )
}

export default ProfessionalNew;
