import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Section, Row, Col, Loading, JS } from 'im-ui-pack';
import { request, ContentTypes } from '../../../store/api/api';
import { PREFIX_URL } from '../../../store/constants';

function ChangeSession() {
  const { redirect_account_id } = useParams();
  const session = JS.getSession();
  const account = session.account;
  const { account_role } = session.account;
  const [redirectAccount, setRedirectAccount] = useState(false);

  useEffect(()=> {
    if(!redirectAccount) {
      changeAccount(redirect_account_id);
    }
  })

  const changeAccount = (redirect_account_id) => {
    console.log('changeAccount: redirect_account_id', redirect_account_id);
    if(account_role==='admin') {
      submitLogin(redirect_account_id);
    }
  }

  const redirectByRole = (role) => {
    switch (role) {
      case 'admin':
        return `https://${PREFIX_URL}admin.improovia.com/dashboard`;
      case 'employee':
        return `https://${PREFIX_URL}employee.improovia.com/dashboard`;
      case 'professional':
        return `https://${PREFIX_URL}professional.improovia.com/dashboard`;
      case 'company':
        return `https://${PREFIX_URL}company.improovia.com/dashboard`;
      default:
        return '';
    }
  }

  setTimeout(function () {
    const session = JS.getSession();
    window.location.href = redirectByRole(session.account.account_role);
  }, 3000);

  const handleRedirection = () => {
    const { account_role } = account;
    switch (account_role) {
      case 'admin': {
        const iframe_admin = `<iframe src='https://${PREFIX_URL}admin.improovia.com/session/index.html' />`;
        return <div className='display-none' dangerouslySetInnerHTML={{ __html: " "+iframe_admin+" "}} />
      }
      case 'employee': {
        const iframe_employee = `<iframe src='https://${PREFIX_URL}employee.improovia.com/session/index.html' />`;
        return <div className='display-none' dangerouslySetInnerHTML={{ __html: " "+iframe_employee+" "}} />
      }
      case 'company': {
        const iframe_company = `<iframe src='https://${PREFIX_URL}company.improovia.com/session/index.html' />`;
        return <div className='display-none' dangerouslySetInnerHTML={{ __html: " "+iframe_company+" "}} />
      }
      case 'professional': {
        const iframe_professional = `<iframe src='https://${PREFIX_URL}professional.improovia.com/session/index.html' />`;
        return <div className='display-none' dangerouslySetInnerHTML={{ __html: " "+iframe_professional+" "}} />
      }
      default:
        return ''
    }
  }

  async function submitLogin(redirect_account_id) {
    const data = {
      "account_id": redirect_account_id
    };

    const endpoint = `/account/session`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const user = res.data;
      console.log('>>>>>>>> user: ', user);
      if (user && user.account_id) {
        JS.setSession({ user });
        //const session_token = 'im-'+JS.setSession({ user });
        // Save Cookie to local machine
       // JS.setCookie({cname:'session_token', cvalue: session_token});
        // Store sessionStorage for access to iframes from externals domains
        //sessionStorage.setItem('session_token', session_token);
        // window.location.href = `https://${user.role}.improovia.com`;
        //const token =  JS.encodeString(JSON.stringify(session_token));
        const session_token = JS.getCookie({cname:'session_token'});
        sessionStorage.setItem('session_token', session_token);
        console.log('user: ',user);
        setRedirectAccount(true);
        //handleRedirection()
        //window.location.href = `./sessions`;
      }
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <div>
      <Section maxWidth='small'>
        <Row>
          <Col>
            <Loading visible={true} className='center'/>
          </Col>
        </Row>
      </Section>
      {redirectAccount && account.account_role ? handleRedirection() : null}
    </div>
  );
}

export default ChangeSession;