import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Section, Row, Col, H2, Tabs, Tab, Table, Thead, Tbody, Tr, Th, Td } from 'im-ui-pack';
import { requestSwitcherAPI, ContentTypes } from '../../../../store/api/api';
// import items from '../../../../store/items/ItemsReducers';
import CompanyChat from './tab/company/Company';
import ProfessionalChat from './tab/professional/Professional';
import './Overview.css';

function ChatOverview() {

  useEffect(() => {});

  return ( 
    <div>
      <Section>
        <Row>
          <Col><H2>Chat</H2></Col>
          <Col className='align-right'>
          </Col>
        </Row>
        <Row>
          <Col>
          
            <Tabs>
              <Tab data='Compañía'>
                <div><CompanyChat /></div>
              </Tab>
              <Tab data='Profesionales'>
                <div><ProfessionalChat /></div>
              </Tab>
            </Tabs>
          
          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default ChatOverview;