import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Section, Row, Col, H2, Tabs, Tab, Table, Thead, Tbody, Tr, Th, Td, Modal, Button, JS } from 'im-ui-pack';
import { request, ContentTypes } from '../../../../store/api/api';
import ModalViewPanel from '../../../../components/modalViewPanel/ModalViewPanel';

function EmployeeOverview() {
  const [employees, setEmployees] = useState();
  const [componentModal, setComponentModal] = useState<any|undefined>(undefined);

  useEffect(() => {
    if(!employees) {
      getEmployees();
    }
  });

  async function getEmployees() {
    const data = {
      "employee_status" : "active"
    };
    const endpoint = `/employee/list`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      setEmployees(result);
    } catch (err) {
      console.log(err)
    }
  };

  function showModalViewPanel(accountItem:any) {
    setComponentModal(<ModalViewPanel close={()=>closeModal()} accountItem={accountItem} />);
  }

  function closeModal() {
    setComponentModal(null);
  }

  return ( 
    <div>
      <Section>
        <Row>
          <Col><H2>Empleados</H2></Col>
          <Col className='align-right'>
          </Col>
        </Row>
        <Row>
          <Col>

            <Table>
              <Thead>
              <Tr>
                <Th>Nombre de profesional</Th><Th>Locación</Th><Th>Fecha de creación</Th><Th></Th>
              </Tr>
              </Thead>
              <Tbody>
              {
                employees ? (
                  employees.map((item: any)=> {
                    return (
                      <Tr key={item.employee_id}>
                        <Td>{item.employee_fullname}</Td><Td>{item.employee_location_city}</Td><Td>{JS.dateFormat.timestampToDate(item.created_at)}</Td>
                        <Td>
                          <div className='position-right'>
                            <Button label='Ver panel' action={()=> showModalViewPanel(item)}/>
                          </div>
                        </Td>
                      </Tr>
                    )
                  })                  
                ) : null
              }
              </Tbody>
            </Table>

          </Col>
        </Row>
      </Section>
      <Modal close={()=>closeModal()} component={componentModal}/>
    </div>
  );
}

export default EmployeeOverview;