import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td, Button, Modal, JS } from 'im-ui-pack';
import { request, ContentTypes } from '../../../../../../store/api/api';
import ModalViewPanel from '../../../../../../components/modalViewPanel/ModalViewPanel';

function CompanyActive() {
  const [companies, setCompanies] = useState();
  const [componentModal, setComponentModal] = useState<any|undefined>(undefined);

  useEffect(() => {
    if(!companies) {
      getCompanies();
    }
  });

  async function getCompanies() {
    const data = {
      "company_status" : "active"
    };
    const endpoint = `/company/list`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      //console.log('result: ', result);
      setCompanies(result);
    } catch (err) {
      console.log(err)
    }
  };

  function showModalViewPanel(accountItem:any) {
    setComponentModal(<ModalViewPanel close={()=>closeModal()} accountItem={accountItem} />);
  }

  function closeModal() {
    setComponentModal(null);
  }

  return (
    <div>
      <Table>
        <Thead>
          <Tr>
            <Th>Empresa</Th><Th>Sucursales</Th><Th>Fecha de creación</Th><Th></Th>
          </Tr>
        </Thead>
        <Tbody>
        {
          companies ? (
            companies.map((item: any)=> {
              const account_id=item.company_admin_accounts[0].account_id;
              const companyItem= {
                account_id: account_id
              }
              return (
                <Tr key={item.company_id}>
                  <Td>{item.company_name}</Td><Td>{item.company_branch_total}</Td><Td>{JS.dateFormat.timestampToDate(item.created_at)}</Td>
                  <Td>
                    <div className='position-right'>
                      <Button label='Ver panel' action={()=> showModalViewPanel(companyItem)}/>
                    </div>
                  </Td>
                </Tr>
              )
            })           
          ) : null
        }
        </Tbody>
      </Table>
      <Modal close={()=>closeModal()} component={componentModal}/>
    </div>
  )
}

export default CompanyActive;
