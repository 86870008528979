import React from 'react';
import SideMenu from '../../components/sideMenu/SideMenu';
import './DashboardLayout.css';
import { Container, HeaderDashboard, JS} from 'im-ui-pack';
import sessionLinks from '../../store/mock/sessionLinks.json';

/*
const user = {
  account_id: 'iiduWQwZhL73cqLBHEY8Lc',
  account_email: 'mclaramunt@improovia.com',
  account_firstname: 'Maria',
  account_lastname: 'Claramunt',
  account_role: 'admin'
};

JS.setSession({ user });
const session_token = JS.getSession();
console.log('session_token: ',session_token);
*/

function DashboardLayout(props: any) {
  return (
    <div className="dashboard-layout">
      <HeaderDashboard sessionLinks={sessionLinks}/>
      <SideMenu />
      <div className='main-wrapper'>
        <Container>
          {props.children}
        </Container>
      </div>
    </div>
  );
}

export default DashboardLayout