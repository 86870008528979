import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td, Button, JS } from 'im-ui-pack';
import { request, ContentTypes } from '../../../../../../store/api/api';

function CompanyNew() {
  const [companies, setCompanies] = useState();

  useEffect(() => {
    if(!companies) {
      getCompanies();
    }
  });

  async function getCompanies() {
    const data = {
      "company_status" : "inactive"
    };
    const endpoint = `/company/list`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      //console.log('result: ', result);
      setCompanies(result);
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <div>
      <Table>
        <Thead>
          <Tr>
            <Th>Empresa</Th><Th>Sucursales</Th><Th>Fecha de creación</Th><Th></Th>
          </Tr>
        </Thead>
        <Tbody>
        {
          companies ? (
            companies.map((item: any)=> {
              return (
                <Tr key={item.company_id}>
                  <Td>{item.company_name}</Td><Td>{item.company_branch_total}</Td><Td>{JS.dateFormat.timestampToDate(item.created_at)}</Td><Td><div className='position-right'><Link to={`/dashboard/company/profile/${item.company_id}`}><Button label='Cambiar estado' action={()=>null}/></Link></div></Td>
                </Tr>
              )
            })           
          ) : null
        }
        </Tbody>
      </Table>
    </div>
  )
}

export default CompanyNew;
