/* Pages */
// import ErrorPage from '../containers/errorPage/ErrorPage';
import IRoute from '../models/route';

// Professional
import ProfessionalOverview from '../pages/private/professional/overview/Overview';
import ProfessionalNewProfile from '../pages/private/professional/overview/tab/new/profile/Profile';
import ProfessionalProfile from '../pages/private/professional/profile/Profile';
import ChangeSession from '../pages/private/changeSession/ChangeSession';

// Company
import CompanyOverview from '../pages/private/company/overview/Overview';
import CompanyNewProfile from '../pages/private/company/overview/tab/new/profile/Profile';
import CompanyProfile from '../pages/private/company/profile/Profile';

// Employee
import EmployeeOverview from '../pages/private/employee/overview/Overview';

// Ofertas
import DealOverview from '../pages/private/deal/overview/Overview';
import AssignProfessional from '../pages/private/deal/assignProfessional/AssignProfessional';

// Accounting
import AccountingOverview from '../pages/private/accounting/overview/Overview';
import PaymentProfessional from '../pages/private/accounting/paymentProfessional/PaymentProfessional';
import SupplyOverview from '../pages/private/supply/Overview';

import ChatOverview from '../pages/private/chat/overview/Overview';
import SolveProfessionalIncidence from '../pages/private/professional/overview/tab/incidence/solve/SolveProfessionalIncidence';

import Validate from '../pages/public/validate/Validate';

// Private
// import Search from '../pages/private/search/Search';

/* Layouts */
//import PublicLayout from '../layouts/publicLayout/PublicLayout';
import DashboardLayout from '../layouts/dashboardLayout/DashboardLayout';
// import PrivateLayout from '../layouts/privateLayout/PrivateLayout';
import FullLayout from '../layouts/fullLayout/FullLayout';

export const routes: IRoute[] = [
  {
    path: '/dashboard/change-session/:redirect_account_id',
    name: 'Redirect Account Page',
    component: ChangeSession,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/professional/incidence/solve/:id',
    name: 'Solve incidence Page',
    component: SolveProfessionalIncidence,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/company/profile/:company_id',
    name: 'Company Profile Page',
    component: CompanyProfile,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/company/new/:id',
    name: 'New Company Page',
    component: CompanyNewProfile,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/professional/profile/:professional_id',
    name: 'Professional Profile Page',
    component: ProfessionalProfile,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/professional/new/:id',
    name: 'New Professional Page',
    component: ProfessionalNewProfile,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/accounting/payment/professional/:id',
    name: 'Payment Professional Page',
    component: PaymentProfessional,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/chat',
    name: 'Chat Page',
    component: ChatOverview,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/supply',
    name: 'Supply Page',
    component: SupplyOverview,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/accounting',
    name: 'Accounting Page',
    component: AccountingOverview,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/meet/assign-professional/:meet_id',
    name: 'Meet Profile Page',
    component: AssignProfessional,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/deal',
    name: 'Deal Page',
    component: DealOverview,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/employee',
    name: 'Employee Page',
    component: EmployeeOverview,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/company',
    name: 'Company Page',
    component: CompanyOverview,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard',
    name: 'Professional Page',
    component: ProfessionalOverview,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/validate/:param',
    name: 'Validate Page',
    component: Validate,
    layout: FullLayout,
    exact: true
  },
  {
    path: '/',
    name: 'Professionals Page',
    component: ProfessionalOverview,
    layout: DashboardLayout,
    exact: true
  }
];